import React from 'react';
import { graphql } from 'gatsby'
import { SEO, SEOProps } from '../components/SEO/SEO';
import { getLocaleMdData } from '../utils/Tools';
import { Page } from '../components/Page';
import ReactMarkdown from 'react-markdown';
import { generateReactFromMarkdown, removeNewLineFromReactComponent } from '../cms/cms-components';
import rehypeRaw from 'rehype-raw';
import { HeroBanner } from '../components/HeroBanner/HeroBanner';
import { CtaBanner } from '../components/CtaBanner/CtaBanner';

const JobPosting = ({ data, pageContext }: JobListingProps) => {
  const _data = getLocaleMdData(data);
  const { language, slugs } = pageContext;

  const {
    theme,
    seo,
    content,
    location,
    ctaText,
    ctaTitle,
    ctaLabel,
    ctaLink
  } = _data[language];

  const hasFrenchContent = _data['fr'].content !== _data['en'].content;

  const globalSEO: SEOProps = (data as any)?.globalSEO?.fields.locales[language].seo[0] || {};

  const { title: seoTitle, description, ogImage } = (seo ? seo[0] : null) || {};

  const title = language === 'en' ? _data.en.title : _data.fr.titleFR;

  return (
    <Page background={theme} language={language} slugs={slugs} showMissingFrenchBanner={language === 'fr' && !hasFrenchContent}>
      <>
        <SEO
          title={seoTitle ? seoTitle : `${title} - ${location}`}
          description={description ? description : globalSEO.description}
          ogImage={ogImage ? ogImage : globalSEO.ogImage} />

        <div className="content">
          <HeroBanner data={{
            text: title,
            subtitle: location
          }} />

          <ReactMarkdown
            components={{ ...generateReactFromMarkdown() }}
            rehypePlugins={[rehypeRaw]}>
            {removeNewLineFromReactComponent(content)}
          </ReactMarkdown>

          <CtaBanner data={{
            background: 'aqua',
            title: ctaTitle,
            text: ctaText,
            ctaLabel,
            ctaLink
          }} />
        </div>
      </>
    </Page>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(id: {eq: $id}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
            theme
            slug
            title
            titleFR
            location
            ctaTitle
            ctaText
            ctaLabel
            ctaLink
            content
          }
          fr {
            ...SeoFragmentFR
            theme
            slug
            title
            titleFR
            location
            ctaTitle
            ctaText
            ctaLabel
            ctaLink
            content
          }
        }
      }
    }
  }
`;

interface JobListingProps {
  pageContext: {
    id: string;
    language: lang;
    slugs: { en: string, fr: string };
  };
  data: LocalizedAllMarkdownDataRelation<CaseStudyData>['data']
}

interface CaseStudyData {
  theme: ThemeBG;
  seo: SEOProps[];
  slug: string;
  title: string;
  titleFR: string;
  location: string;
  ctaText: string;
  ctaTitle: string;
  ctaLabel: string;
  ctaLink: string;
  content: string;
}

export default JobPosting;